import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'GtrDetailsAdminUserView'
})
export default class GtrDetailsAdminUserView extends GtrSuper {
  data () {
    return {
      user: null,
      user_uuid: this.$route.params.user_uuid
    }
  }

  async mounted () {
    await this.fetchUser(this.$data.user_uuid)
    this.$data.user = this.$store.state.adminUser.user
  }

  async impersonateUser (user: any) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('security/impersonateUser', user.uuid)
      this.$router.go(0)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchUser (user_uuid) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('adminUser/fetchAdminUser', user_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
